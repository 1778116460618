import {
  Flex,
  Box,
  Text,
  HStack,
  VStack,
  Link as ChakraLink,
  AspectRatio,
} from "@chakra-ui/react";
import React from "react";
import AppleStoreSvg from "components/chakra-ui/svg/apple-store.svg";
import GoogleStoreSvg from "components/chakra-ui/svg/google-store.svg";
import NextImage from "next/image";

const ReadAnywhere: React.FC = () => {
  return (
    <Box bg="linear-gradient(180deg, #FFFFFF 41.6%, #EEEEEE 100%)">
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="center"
        mx="auto"
        mt={{ base: 6, lg: 10 }}
        px={{ base: 4, md: 20 }}
        gap={4}
      >
        <VStack
          justifyContent={{ base: "center", lg: "flex-start" }}
          alignItems={{ base: "stretch", lg: "flex-start" }}
          mb={{ base: 3, lg: 0 }}
          mr={{ lg: 16 }}
        >
          <Text
            textStyle="headers.primary"
            as="h2"
            mr="auto"
            fontWeight="black"
          >
            Read anywhere
          </Text>
          <Text
            color="ui.bodyText"
            fontWeight={{ base: "normal" }}
            lineHeight={{ base: "shorter", sm: "base" }}
            fontSize={{ base: "md", md: "xl" }}
            pb={4}
            maxW="lg"
          >
            Download the mobile app to browse and read from an Android or iOS
            phone or tablet.
          </Text>
          <Flex
            gap={{ base: 2, sm: 5 }}
            alignItems="center"
            justifyContent="center"
            pt={{ lg: "6" }}
          >
            <ChakraLink
              href="https://apps.apple.com/us/app/open-ebooks/id1033669250"
              isExternal
            >
              <AspectRatio ratio={164 / 55} width={{ base: "40vw", md: 164 }}>
                <NextImage
                  src={AppleStoreSvg.src}
                  alt="Apple Store Logo"
                  fill
                />
              </AspectRatio>
            </ChakraLink>
            <ChakraLink
              href="https://play.google.com/store/apps/details?id=org.nypl.labs.OpenEbooks.app&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              isExternal
            >
              <AspectRatio ratio={179 / 53} width={{ base: "44vw", md: 179 }}>
                <NextImage
                  src={GoogleStoreSvg.src}
                  alt="Google Store Logo"
                  fill
                />
              </AspectRatio>
            </ChakraLink>
          </Flex>
        </VStack>
        <HStack gap={{ md: 5 }} alignItems="center" justifyContent="center">
          <AspectRatio ratio={2.21 / 1} width={765}>
            <NextImage
              src="/img/OpenEbookMobileAndDesktopScreenshot.png"
              alt="OPEN eBOOKS Mobile and Desktop screenshot"
              fill
            />
          </AspectRatio>
        </HStack>
      </Flex>
    </Box>
  );
};

export default ReadAnywhere;
