import { Box } from "@chakra-ui/react";
import React from "react";

const HeroImage: React.FC = () => {
  return (
    <Box
      backgroundImage='url("/img/homepage-hero-img.png")'
      backgroundAttachment="fixed"
      backgroundPosition="center 4rem"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      height="24vw"
    />
  );
};

export default HeroImage;
