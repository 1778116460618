import {
  Flex,
  Text,
  HStack,
  VStack,
  Box,
  Icon,
  Button,
  AspectRatio,
} from "@chakra-ui/react";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import NextImage from "next/legacy/image";
import Link from "next/link";
import { LOANS_PATH } from "utils/constants";
import useGetCredentials from "hooks/useGetCredentials";
import { AuthButton } from "components/chakra-ui/AuthButton";
import { NumberedCircle } from "components/chakra-ui/mdx/HowItWorks";
import LogoSvg from "components/chakra-ui/svg/logo-horizontal.svg";
import HowItWorksLeftSvg from "components/chakra-ui/svg/how-it-works-bg-left.svg";
import HowItWorksRightSvg from "components/chakra-ui/svg/how-it-works-bg-right.svg";

const HowItWorksSection: React.FC = () => {
  const credentials = useGetCredentials();
  const isAuthenticated = !!credentials;
  return (
    <>
      {!isAuthenticated && (
        <Flex flexDir={{ base: "column-reverse", lg: "row" }} w="100%">
          <HowItWorks />
          <StartReading />
        </Flex>
      )}
      {isAuthenticated && <StartReadingAuth />}
    </>
  );
};

const steps = [
  {
    idx: 1,
    icon: BackHandOutlinedIcon,
    description: "Ask a teacher or school librarian for access",
  },
  {
    idx: 2,
    icon: PasswordOutlinedIcon,
    description: "Sign in",
  },
  {
    idx: 3,
    icon: LocalLibraryOutlinedIcon,
    description: "Browse thousands of ebooks and start reading",
  },
];
const HowItWorks = () => {
  return (
    <Flex
      flexBasis={{ base: "100%", md: "50%" }}
      bgImage={HowItWorksLeftSvg.src}
      borderBottom="4px solid"
      borderColor="ui.teal.100"
    >
      <VStack
        ml={{ base: 2, md: "auto" }}
        mr={{ base: 2, md: "auto" }}
        mb={10}
        px={{ base: 4, md: 8 }}
        alignItems="flex-start"
        width={{ base: "100%", md: "auto" }}
      >
        <Text
          textStyle="headers.secondary"
          as="h2"
          fontWeight="black"
          my={{ base: 2, md: 4 }}
          ml={{ base: 2, md: 9 }}
        >
          How It Works
        </Text>
        <VStack alignItems="center" width={{ base: "100%", md: "auto" }}>
          {steps.map((step) => (
            <HStack
              key={step.idx}
              border="5px solid"
              borderColor="ui.teal.100"
              borderRadius="50px"
              bgColor="ui.white"
              px={{ base: 2, md: 6 }}
              py={{ base: 1, md: 4 }}
              gap={{
                base: 2,
                md: 14,
              }}
              w="100%"
            >
              <NumberedCircle
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize={{ base: "xl", md: "7xl" }}
                h={{ base: "40px", md: "70px" }}
                w={{ base: "40px", md: "70px" }}
                marginRight={0}
              >
                {step.idx}
              </NumberedCircle>
              <Icon as={step.icon} fontSize={{ base: "xl", md: "9xl" }} />
              <Text
                flex={1}
                fontSize={{ base: "md", md: "xl" }}
                lineHeight={{ base: "none", md: "base" }}
                fontWeight="normal"
                as="span"
                pr={6}
              >
                {step.description}
              </Text>
            </HStack>
          ))}
        </VStack>
      </VStack>
    </Flex>
  );
};

const StartReading = () => {
  return (
    <Flex
      bgImage={`/**/${`url(${HowItWorksRightSvg.src}), linear-gradient(180deg, #0A99A0 0%, #124C4F 100%)`}`}
      alignItems="center"
      justifyContent="center"
      flexBasis={{ base: "100%", sm: "50%" }}
      p={{ base: 4, md: 8 }}
    >
      <VStack
        border="7px solid"
        borderColor="ui.teal.400"
        borderRadius="40px"
        bgColor="ui.white"
        maxWidth={"560px"}
        w="100%"
        py={{ base: 5, md: 9 }}
        gap={1}
      >
        <AspectRatio
          ratio={432 / 124}
          mt={{ base: "-35px", md: "-65px" }}
          width={{ base: 210, sm: 250, md: 430 }}
        >
          <NextImage src={LogoSvg.src} alt="OPEN eBOOKS logo" layout="fill" />
        </AspectRatio>
        <Text
          fontWeight="normal"
          fontSize={{ base: "md", md: "xl" }}
          lineHeight={{ base: "shorter", md: "base" }}
          px={{ base: 4, md: 24 }}
          py={{ base: 1 }}
        >
          Start reading thousands of ebooks for free! Sign in on your favorite
          web browser or download the app to get started.
        </Text>
        <AuthButton colorScheme="magenta" size="xl" maxW="90%" />
      </VStack>
    </Flex>
  );
};

const StartReadingAuth = () => {
  return (
    <Flex
      border="5px solid"
      borderColor="ui.teal.100"
      bgImage={`${`url(${HowItWorksLeftSvg.src})`}`}
      alignItems="center"
      justifyContent="center"
      flexBasis={"100%"}
      py={{ base: 4, md: 8 }}
    >
      <Box
        display="flex"
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        border="7px solid"
        borderColor="ui.teal.100"
        borderRadius="40px"
        bgColor="ui.white"
        maxW={{ sm: "30.25rem", lg: "68.5rem" }}
        w="100%"
        mx={{ base: 1, sm: 6, lg: "auto" }}
        py={{ base: 5, md: 9 }}
        px={{ base: 4, lg: 14 }}
        gap={1}
      >
        <Box maxW="30rem">
          <AspectRatio
            ratio={432 / 124}
            mt={{ base: "-35px", md: "-65px" }}
            width={{ base: 210, sm: 250, md: 400, lg: 430 }}
          >
            <NextImage src={LogoSvg.src} alt="OPEN eBOOKS logo" layout="fill" />
          </AspectRatio>
          <Text
            fontWeight="normal"
            fontSize={{ base: "md", md: "xl" }}
            lineHeight={{ base: "shorter" }}
            px={{ base: 4 }}
            py={{ base: 1 }}
          >
            Start reading thousands of ebooks for free! Jump back into your
            books.
          </Text>
        </Box>
        <Link href={LOANS_PATH} passHref legacyBehavior>
          <Button
            maxW={{ base: "14rem", md: "22.5rem" }}
            mt={{ base: 8, md: 12, lg: 0 }}
            mb={{ base: 2, md: 6, lg: 0 }}
            as="a"
            colorScheme="magenta"
            fontWeight="bold"
            size="xl"
            pos="relative"
            display="flex"
            leftIcon={<LocalLibraryOutlinedIcon fontSize="inherit" />}
            iconSpacing={{ base: 3, md: 5 }}
          >
            My Books
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};

export default HowItWorksSection;
