import { Box, Text, GridItem, Grid, AspectRatio } from "@chakra-ui/react";
import React from "react";
import TakeAPeakSvg from "components/chakra-ui/svg/take-a-peek.svg";
import NextImage from "next/legacy/image";

const TakeAPeak: React.FC = () => {
  return (
    <Box bgColor="ui.white" maxW={{ md: "54rem", lg: "90rem" }} m="auto" px={5}>
      <Grid
        templateAreas={{
          base: `
          "image header"
          "text text"
          `,
          sm: `
          "image header"
          "image text"
          `,
        }}
        gridTemplateRows={"auto 1fr"}
        gridTemplateColumns={{
          base: "1fr auto",
          md: "100px 1fr",
          lg: "125px 1fr",
        }}
        border="6px dashed"
        borderColor="ui.magenta.400"
        borderRadius="40px"
        bgColor="ui.magenta.100"
        ml={{ base: 3, sm: 4, lg: 40 }}
        mr={{ base: 3, sm: 4 }}
        my={{ base: 6, lg: 7 }}
      >
        <GridItem
          area="image"
          ml={{ base: -10, lg: -20 }}
          mt={{ base: -4.5, lg: "default" }}
        >
          <AspectRatio
            ratio={1.3}
            transform={{ lg: "scale(1.25, 1.3)" }}
            w={{ base: "90px", md: "140px", lg: "165px" }}
            pt={{ lg: 7 }}
            mt={{ lg: 5 }}
          >
            <NextImage src={TakeAPeakSvg.src} alt="Take a Peek" layout="fill" />
          </AspectRatio>
        </GridItem>
        <GridItem area="header">
          <Text
            as="h2"
            color="ui.magenta.400"
            fontSize={{ base: "xl", md: "4xl", lg: "7xl" }}
            fontWeight={{ base: "black", lg: "bold" }}
            lineHeight={{ base: "shorter", md: "shorter" }}
            mt={{ base: 4, lg: 3 }}
            mx={{ base: 4, lg: 0 }}
            mr={2}
          >
            Start your next chapter with Open eBooks
          </Text>
        </GridItem>
        <GridItem area="text">
          <Text
            color="ui.bodyText"
            fontSize={{ base: "md", md: "xl" }}
            fontWeight={{ base: "normal" }}
            lineHeight={{ base: "shorter", md: "base" }}
            my={{ base: 4, lg: 1 }}
            mt={2}
            mx={{ base: 4, sm: 4, lg: 0 }}
            pb={{ lg: 6 }}
            maxW="lg"
          >
            Open eBooks has elementary, middle, and high school collections. See
            some of our most popular titles below.
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default TakeAPeak;
